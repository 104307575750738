import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FormatDatePipe } from './format-date.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
import * as moment from 'moment';
import { SharedDataService } from '../services/shared-data.service';
@Pipe({
    name: 'formatDataPipe'
})
export class FormatDataPipe implements PipeTransform {

    constructor(private sharedData: SharedDataService, private domSanitizer: DomSanitizer) {
    }
    transform(input: any, ...args: any[]) {

        let fdtp = new FormatDatePipe();
        let sanitizeHtmlPipe = new SanitizeHtmlPipe(this.domSanitizer);

        let type = args[0] != undefined ? args[0] : '';
        let param1 = args[1] != undefined ? args[1] : '';
        let param2 = args[2] != undefined ? args[2] : '';

        let output = '';
        switch (type) {
            case 'MBKB':
                if (typeof (input) == 'number') {
                    if (input < 1024) {
                        output = input + "B";
                    } else {
                        if (input < 1048576) {
                            output = (input / 1024).toFixed(2) + "KB";
                        } else {
                            if (input < 1073741824) {
                                output = (input / 1048576).toFixed(2) + "MB";
                            } else {
                                output = (input / 1073741824).toFixed(2) + "GB";
                            }
                        }
                    }
                } else {
                    output = input;
                }
                break;

            case 'DecToHex':
                let isHex = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(input);
                if (isHex) {
                    output = input;
                } else {
                    output = '#' + ('000000' + (input & 0xFFFFFF).toString(16)).slice(-6);
                }
                break;

            case 'ByteArrayToBase64':
                let binary = '';
                let bytes = new Uint16Array(input);
                let len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                output = 'data:image/jpeg;base64,' + window.btoa(binary);
                break;

            case 'FormatCardTitleRight':
                if (input.indexOf('/Date(') > -1) {
                    let dateStr = fdtp.transform(input, 'DateShort');
                    let date = new Date(dateStr);
                    date = this.transform(date, 'MomentFormat');
                    return this.transform(date, 'Capitalize').replace(".", "");
                }
                break;

            case 'MomentFormat':
                moment.locale(navigator.language);

                let customLongDateFormat: any = {
                    LT: "HH:mm",
                    LL: "D MMM",
                    L: "D MMM YY"
                };

                let customCalendar: any = {
                    'lastDay': 'L', //yesterday
                    'sameDay': 'LT',
                    'nextDay': 'L', //tomorrow
                    'lastWeek': 'L',
                    'nextWeek': 'L',
                    'sameElse': 'L'
                }

                moment.updateLocale(navigator.language, {
                    longDateFormat: customLongDateFormat, calendar: customCalendar
                });

                output = moment(input).calendar();
                break;

            case 'Capitalize':
                if (input) {
                    output = input.charAt(0).toUpperCase() + input.slice(1);
                }
                return output;

            case 'HtmlToPlainText':
                let regex = new RegExp(/<style[^>]*>([\w|\W]*)<\/style>/im);
                let str = input ? input.replace(regex, '') : '';
                str = str.replace(/<[^>]+>/gm, '');
                str = str.replace(/\s\s+/g, '');
                str = str.replace(/&nbsp;/g, ' ');
                str = str.replace(/&lt;/g, '<');
                str = str.replace(/&gt;/g, '>');

                str = str.replace(/&aacute;/g, 'á');
                str = str.replace(/&Aacute;/g, 'Á');

                str = str.replace(/&eacute;/g, 'é');
                str = str.replace(/&Eacute;/g, 'É');

                str = str.replace(/&iacute;/g, 'í');
                str = str.replace(/&Iacute;/g, 'Í');

                str = str.replace(/&ouml;/g, 'ö');
                str = str.replace(/&Ouml;/g, 'Ö');
                str = str.replace(/&&#337;/g, 'ő');
                str = str.replace(/&#336;/g, 'Ő');
                str = str.replace(/&oacute;/g, 'ó');
                str = str.replace(/&Oacute;/g, 'Ó');

                str = str.replace(/&uuml;/g, 'ü');
                str = str.replace(/&Uuml;/g, 'Ü');
                str = str.replace(/&&#369;/g, 'ű');
                str = str.replace(/&#368;/g, 'Ű');
                str = str.replace(/&uacute;/g, 'ú');
                str = str.replace(/&Uacute;/g, 'Ú');

                output = str;
                break;

            case 'CheckData':
                if (typeof (input) != 'undefined' && input != null) {
                    if (typeof (input) !== 'number') {
                        if (typeof (input) === 'object') {// for Dates
                            output = fdtp.transform(input, param2);
                        } else {
                            if (typeof (input) === 'boolean') {
                                output = String(input);
                            } else {
                                if (input.indexOf('/Date(') == -1) {
                                    if (param1.indexOf('Remarks') == -1) {
                                        output = input;
                                    } else {
                                        output = this.transform(input, 'Remarks', param2);
                                    }
                                } else {
                                    output = fdtp.transform(input, param2);
                                }
                            }
                        }
                    } else {
                        output = String(input);
                    }
                } else {
                    output = null;
                }
                break;

            case 'Remarks':
                let StringEmail = input;//String email to sanitaze
                let IndexEndString = StringEmail.length;
                let IndexEndHtml = StringEmail.toLowerCase().indexOf("<\/html>") + 1 + 6;

                if (IndexEndHtml == 6) {
                    IndexEndHtml = StringEmail.toLowerCase().indexOf("<\/bw2html>") + 1 + 9;
                }

                if (IndexEndHtml == 9) {
                    IndexEndHtml = IndexEndString;
                }
                let HtmlEmailSanitaze = StringEmail.substring(0, IndexEndHtml);
                let regex2 = /href="([\S]+)"/g;
                let result = HtmlEmailSanitaze.replace(regex2, "onClick=\"window.MainPage.openUrl('$1')\"");

                regex2 = new RegExp(/<head[^>]*>([\w|\W]*)<\/head>/im);// To remove styles
                result = result.replace(regex2, '');

                regex2 = new RegExp(/text-indent(.*?);/gi);// to remove text-indent
                result = result.replace(regex2, '');

                regex2 = new RegExp(/<!\[(.*?)\]>/gi);// to remove <![]> tags
                result = result.replace(regex2, '');

                if (param1=='HTML' || param1 == 'BW2HTML') {
                    sanitizeHtmlPipe.transform(result);
                    output = result;
                    //output = $sce.trustAsHtml(result);
                } else {
                    output = result;
                }
                break;

            case 'GetExtension':
                let ext = input.split('.')[input.split('.').length - 1];
                output = '.' + ext;
                break;

            case 'GetIconExtension':
                if (input != undefined && input != '') {
                    let img = input.toLowerCase();
                    switch (img) {
                        case '.rtf':
                            output = 'nu-rtf';
                            break;
                        case '.docx':
                            output = 'nu-doc';
                            break;
                        case '.doc':
                            output = 'nu-doc';
                            break;
                        case '.pdf':
                            output = 'nu-pdf';
                            break;
                        case '.txt':
                            output = 'nu-txt';
                            break;
                        case '.xls':
                            output = 'nu-xlsx';
                            break;
                        case '.xlsx':
                            output = 'nu-xlsx';
                            break;
                        case '.csv':
                            output = 'nu-xlsx';
                            break;
                        case '.xlr':
                            output = 'nu-xlsx';
                            break;
                        case '.ppt':
                            output = 'nu-pptx';
                            break;
                        case '.pptx':
                            output = 'nu-pptx';
                            break;
                        case '.pps':
                            output = 'nu-pptx';
                            break;
                        case '.3gp':
                        case '.asf':
                        case '.avi':
                        case '.flv':
                        case '.mov':
                        case '.mp4':
                        case '.mpg':
                        case '.rm':
                        case '.swf':
                        case '.wmv':
                            output = 'nu-video-64';
                            break;
                        case '.bmp':
                        case '.gif':
                            output = 'nu-gif';
                            break;
                        case '.jpg':
                            output = 'nu-jpg';
                            break;
                        case '.png':
                            output = 'nu-png';
                            break;
                        case '.psd':
                        case '.tif':
                        case '.tiff':
                        case '.jpeg':
                            output = 'nu-jpg';
                            break;
                        case '.7z':
                        case '.gz':
                        case '.pkg':
                        case '.rar':
                            output = 'nu-rar';
                            break;
                        case '.zip':
                            output = 'nu-zip';
                            break;
                        case '.zipx':
                            output = 'nu-zip';
                            break;
                        case '.c':
                        case '.java':
                        case '.cpp':
                        case '.cs':
                        case '.pl':
                        case '.py':
                        case '.sh':
                        case '.sln':
                        case '.vb':
                        case '.dtd':
                        case '.fla':
                        case '.h':
                        case '.m':
                            output = 'nu-code';
                            break;
                        default:
                            output = 'nu-single-folded';
                            break;

                    }
                }
                break;

            case 'GetImageURLByKey':
                if (input != undefined && input != '') {
                    output = this.sharedData.appURL + '?DocumentKey=' + input + '&DocumentType=res';
                }
                break;

            case 'GetImageURLByGuid':
                if (input != undefined && input != '') {
                    output = this.sharedData.appURL + '?DocumentGuid=' + input;
                }
                break;

            case 'GetDocumentThumbnail':
                if (input != undefined && input != '') {
                    output = this.sharedData.appURL + '?DocumentDataGuid=' + input + '&DocumentType=thumbnail';
                }
                break;

            case 'GetTranslationCaption':
                if (input != undefined && input != '') {
                    let caption = this.sharedData.translations.find(tr => tr['Translation_Code'].toLowerCase() == input.toLowerCase());
                    if (caption != undefined) {
                        output = caption['Translation_Caption'];
                    } else {
                        output = input;
                    }
                }
                break;
            case 'GetDisplayFormatDate':
                if (input == 'DateLongDate') {
                    output = 'MMMM d, y';
                } else {
                    if (input == 'DateShortDate') {
                        output = 'M/d/yy';
                    } else {
                        if (input == 'DateShort') {
                            output = 'M/d/yy h:mm a';
                        } else {
                            if (input == 'DateMedium') {
                                output = 'MMM d, y h:mm:ss a';
                            } else {
                                if (input == 'DateFullDate') {
                                    output = 'EEEE, MMMM d, y';
                                } else {
                                    if (input == 'DateShortTime') {
                                        output = 'h:mm a';
                                    } else {
                                        if (input == 'DateMediumTime') {
                                            output = 'h:mm:ss a';
                                        } else {
                                            if (input == 'DateMediumDate') {
                                                output = 'MMM d, y';
                                            } else {
                                                output = 'MMM d, yyyy';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                break;

            case 'GetDateType':
                if (input == 'DateLongDate') {
                    output = 'date';
                } else {
                    if (input == 'DateShortDate') {
                        output = 'date';
                    } else {
                        if (input == 'DateShort') {
                            output = 'datetime';
                        } else {
                            if (input == 'DateMedium') {
                                output = 'datetime';
                            } else {
                                if (input == 'DateFullDate') {
                                    output = 'date';
                                } else {
                                    if (input == 'DateShortTime') {
                                        output = 'time';
                                    } else {
                                        if (input == 'DateMediumTime') {
                                            output = 'time';
                                        } else {
                                            if (input == 'DateMediumDate') {
                                                output = 'date';
                                            } else {
                                                output = 'datetime';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                break;

                case 'GetAssetIconFromGUID':
                    switch (input) {
                        case '25f94278-a862-405c-81ef-c1c8c59659b6':
                            output = 'email.png';
                            break;
                        case 'd0021790-25e9-4648-9ec2-884273394b9c':
                            output = 'nu-todo.png';
                            break;
                        case '7dd6f515-f455-4549-8a74-b4e4f8576d69':
                            output = 'nu-calendar-57.png';
                            break;
                        case 'bb6f9add-944b-4594-9981-5a297a986902':
                            output = 'phone.png';
                            break;
                        case 'faf108a1-c9cc-4d42-9357-e8021cd5dad1':
                            output = 'nu-service.png';
                            break;
                        case '7722e5a1-0fec-4dde-9037-94395ca1daf7':
                            output = 'supportrequest.png';
                            break;
                        case '4906755f-bfc7-4939-b2bd-693d6535fc36':
                            output = 'changerequest.png';
                            break;
                        case '82435029-c3ab-4829-a291-830655dd4066':
                            output = 'nu-book-open.png';
                            break;
                        case '1168418a-e266-4552-8a26-d078a2a70027':
                            output = 'nu-project.png';
                            break;
                        case 'ac83260e-4585-4df0-bc37-8c90649a4e27':
                            output = 'nu-account.png';
                            break;    
                        case '3ea4dcc3-c9cd-47dc-969f-a49d406e9704':
                            output = 'nu-contacts-2.png';
                            break;    
                        case '131c25cc-2c4b-4f8a-8783-551db4613042':
                            output = 'nu-business-contact-85.png';
                            break;    
                        case '7316f2e8-b2ae-41df-a657-053080a3f79d':
                            output = 'nu-personnelcontact.png';
                            break;    
                        case '704b5049-e657-4d8e-8328-d6d6e0141384':
                            output = 'nu-man-37.png';
                            break;   
                        case '1d30186d-9b85-4f60-9bd6-d68241c3ef4a':
                            output = 'nu-cheque.png';
                            break;   
                        case '19129947-d5aa-4a25-be94-b5fadc13b24f':    
                            output = 'topic.png';
                            break;
                        case 'd9555f21-106a-4000-9688-5304db157431':
                            output = 'meeting.png';
                            break; 
                        case '2fe367fb-b24a-4b74-b303-79a998bb33ab':
                            output = 'check-out.png';
                            if(param1=='checkintime')
                                output = 'check-in.png';
                            break;   
                        case 'e27336cb-d71d-4fc0-9bc8-22e1ba86f702':
                            output = 'nu-chat-45.png';
                            break;
                        case '96037eb0-8fd5-4a41-a227-4434f587d5b7':
                            output = 'nu-chat-round.png';
                            break;
                        case '4023bdc4-287d-432b-9752-2ddb45320d93':
                            output = 'nu-target.png';
                            break;
                        default:
                            output = 'nu-add.png';
                            break;
                    }
                    break;        
                
                case 'GetMimeTypeByExtension':
                    switch (input) {
                        case '.flv':
                            output = 'video/x-flv';
                            break;
                        case '.mp4':
                            output = 'video/mp4';
                            break;
                        case '.m3u8':
                            output = 'application/x-mpegURL';
                            break;
                        case '.ts':
                            output = 'video/MP2T';
                            break;
                        case '.3gp':
                            output = 'video/3gpp';
                            break;
                        case '.mov':
                            output = 'video/quicktime';
                            break;
                        case '.avi':
                            output = 'video/x-msvideo';
                            break;
                        case '.wmv':
                            output = 'video/x-ms-wmv';
                            break;
                        default:
                            output = '';
                            break;
                    }
                    break;      
        }
        return output;
    }
}
